import { FC } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import HubInputField from "../../inputType";
import AddCasePhoneNumber from "../addCasePhoneNumber";

import { AddCasePatientInformaitonProps } from "./types";
import { PATIENT_GENDER, PATIENT_TIME_TO_CONTACT, PATIENT_TITLE } from "src/constants/cases";
import { PatientPhoneNumber } from "src/pages/addCase/types";

const AddCasePatientInformation: FC<AddCasePatientInformaitonProps> = ({ control, watch, getValues, setValue }): JSX.Element => {
  const patientListing = watch("patient_attributes.phone_numbers") || [];


  const removePhoneNumber = (index: number): void => {
    const phoneNumberlisting: PatientPhoneNumber[] = getValues("patient_attributes.phone_numbers");
    phoneNumberlisting.splice(index, 1);
    setValue("patient_attributes.phone_numbers", phoneNumberlisting);
  };

  const addNewPhoneNumber = (): void => {
    const phoneNumbers: PatientPhoneNumber[] = getValues("patient_attributes.phone_numbers") || [];
    const newPhoneNumber = {
      phone_type: "cell",
      id: null,
    } as PatientPhoneNumber;
    phoneNumbers.push(newPhoneNumber);
    setValue("patient_attributes.phone_numbers", phoneNumbers);
  };

  return (
    <div className="card m-1 mb-3">
      <div className="card-header">
        <h4 className="m-0">Patient Information</h4>
      </div>

      <div className="card-body">
        <Controller
          name="patient_attributes.title"
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <HubInputField
              inputId="patient_attributes.title"
              inputLabel="Patient Title"
              isRequired={false}
              isRowBased={true}
              field={field}
              error={error}
              isDropdown={true}
              isSearchable={false}
              dropdownData={PATIENT_TITLE as unknown as OptionsOrGroups<string, GroupBase<string>>}
            />
          )}
        />

        <div className="row">
          <label className="form-label col-lg-2 col-md-4 col-12">Patient Name *</label>
          <div className="col-lg-10 col-md-8 col-12">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <Controller
                  name="patient_attributes.firstname"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <HubInputField
                      inputId="patient_attributes.firstname"
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      inputPlaceholder="First Name *"
                      error={error}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-6">
                <Controller
                  name="patient_attributes.middlename"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <HubInputField
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      inputId="patient_attributes.middlename"
                      inputPlaceholder="Middle Name"
                      error={error}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-6">
                <Controller
                  name="patient_attributes.lastname"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <HubInputField
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      inputId="patient_attributes.lastname"
                      inputPlaceholder="Last Name *"
                      error={error}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <label className="form-label col-lg-2 col-md-4 col-12">Gender *</label>
          <div className="col-lg-2 col-md-8 col-12">
            <Controller
              name="patient_attributes.gender"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  inputId="patient_attributes.gender"
                  isRequired={true}
                  isRowBased={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  dropdownData={PATIENT_GENDER as unknown as OptionsOrGroups<string, GroupBase<string>>}
                />
              )}
            />
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <Controller
              name="patient_attributes.dob"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <div className="row align-items-center">
                  <label htmlFor="patient_attributes.dob" className="col-4 form-label text-end mb-0">
                    DOB *
                  </label>
                  <div className="col-8">
                    <input
                      type="date"
                      required
                      max={new Date().toISOString().substring(0, 10)}
                      className={`form-control ${error && error.message ? 'is-invalid' : ''}`}
                      id="patient_attributes.dob"
                      {...field}
                    />
                    {error && error.message ? (
                      <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2 "></div>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <Controller
              name="patient_attributes.ssn"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRequired={false}
                  field={field}
                  inputId="patient_attributes.ssn"
                  inputLabel="SSN"
                  inputPlaceholder="123-45-6789"
                  error={error}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <label className="form-label col-lg-2 col-md-4 col-12">Email</label>
          <div className="col-lg-3 col-md-3 col-12">
            <Controller
              name="patient_attributes.patient_email"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRequired={false}
                  field={field}
                  inputId="patient_attributes.patient_email"
                  inputPlaceholder="abc@example.com"
                  error={error}
                />
              )}
            />
            <Controller
              name="patient_attributes.patient_email_id"
              control={control}
              defaultValue={null}
              render={() => <></>}
            />
          </div>
          <label className="form-label col-lg-2 col-md-4 col-12">Best Time to Contact</label>
          <div className="col-lg-5 col-12">
            <Controller
              name="patient_attributes.best_time_to_contact"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  inputId="patient_attributes.best_time_to_contact"
                  isRequired={false}
                  isRowBased={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  dropdownData={PATIENT_TIME_TO_CONTACT as unknown as OptionsOrGroups<string, GroupBase<string>>}
                />
              )}
            />
          </div>
        </div>

        {patientListing && patientListing.map((_d, index) => (
          <AddCasePhoneNumber key={index} control={control} phoneNumberIndex={index} removePhoneNumber={removePhoneNumber}/>
        ))}

        <div className="text-end mt-lg-2 mt-md-2 mt-0">
          <button type="button" className="btn btn-success" onClick={addNewPhoneNumber}>
            <i className="bi bi-plus-lg"></i> Add Phone Number
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCasePatientInformation;
