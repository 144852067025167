/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from "react";
import StatsReports from "../statsReports";
import { Controller, useForm } from "react-hook-form";
import { AccountManagerAPIProps, ProductRequestProps, StatisticsPropsType, StatisticsSearchProps } from "./types";
import HubInputField from "src/components/inputType";
import Select, {GroupBase, OptionsOrGroups, PropsValue} from "react-select";
import { dataSitofCare } from "src/constants/contact";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import {AuthContext} from "../../../context/authContext";




const CaseFilter: FC<StatisticsPropsType> = ({
  updateQueryParams,
  defaultQueryParams,
}): JSX.Element => {
  const [userListing, updateUserListing] = useState<AccountManagerAPIProps>([]);
  const { userData } = useContext(AuthContext);

  const { control, handleSubmit, getValues, setValue } = useForm<StatisticsSearchProps>({
    mode: "all",
    defaultValues: defaultQueryParams,
  });
  // const timeData = [
  //   { value: "day", label: "Day" },
  //   { value: "week", label: "Week" },
  //   { value: "month", label: "Month" },
  //   { value: "quarter", label: "Quarter" },
  // ];

  // const filterCaseData = [
  //   { value: "all", label: "All Cases" },
  //   // { value: "ecrall", label: "ECR Cases" },
  //   // { value: "ncrall", label: "Non-ECR Cases" },
  //   { value: "parecrall", label: "PA Pending" },
  //   { value: "ncrparall", label: "Missing Docs" },
  // ];


  /**
  * @GetProductListing API to call GetProductListing
  */
  const { data: productData, error: productError } = useFetch<ProductRequestProps[]>(APIEndpoints.GET_PRODUCTS);

  useEffect(() => {
    if (productData || productError) {
      if (userData && userData.ref_type !== "rep")
        getUserListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, productError]);



  const { data: userListingData, mutate: getUserListing } =
    useFetch<AccountManagerAPIProps>(APIEndpoints.GET_USER_FOR_ACCOUNT_MANAGER, {}, {}, false);

  useEffect(() => {
    if (userListingData) {
      if (userData && userData.ref_type === "rep") {
        const data = userListingData.find((user) => user.id === userData?.id);
        if (!data)
          updateUserListing([...userListingData, { id: userData?.id || 0, fullname: userData?.name || "" }])
        else
          updateUserListing(userListingData)

        setValue("user_id", userData ? userData.id.toString() : "")
      } else {
        updateUserListing(userListingData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListingData]);

  



  const searchForStats = (values: StatisticsSearchProps) => {
    const updatedValues = {
      ...values,
      period: values.period
        ? typeof values.period === "string"
          ? values.period
          : (values.period as unknown as { value: string; label: string }).value
        : "",
      soc_value:
        values.soc_value ?
          (typeof values.soc_value === "string" ? values.soc_value : (values.soc_value as unknown as { value: string; name: string; }).value)
          : "",
      drug_id:
        values.drug_id ?
          (typeof values.drug_id === "string" ? values.drug_id : (values.drug_id as unknown as { value: string; name: string; }).value)
          : "",
      // filter: values.filter
      //   ? typeof values.filter === "string"
      //     ? values.filter
      //     : (values.filter as unknown as { value: string; label: string }).value
      //   : "",
      user_id:
        values.user_id ?
          (typeof values.user_id === "string" ? values.user_id : (values.user_id as unknown as { value: string; name: string; }).value)
          : "",
    };

    updateQueryParams(updatedValues, false);
  };

  const populateCurrentUserInDropdown = (value: string) => {
    if (userListing && userListing.length > 0) {
      const data = userListing.find((option) =>
        option.id.toString() === value
      );

      if (data) return { value: data.id.toString(), label: data.fullname } as unknown as PropsValue<string>;
    }
  };

  return (
    <form onSubmit={handleSubmit(searchForStats)}>
      <div className="card mt-4">
        <div className="card-header">
          <h4 className="m-0">Statistics</h4>
        </div>
        <div className="card-body px-3 pt-3">
          <div className="row g-2">
            <div className="col-12 col-sm-12 col-md-11 col-lg-11">
              <div className="row g-2">
                <div className="col-lg-5">
                  <div className="row g-2">
                    <div className="col-12 col-sm-12 col-md-6">
                      <Controller
                        name="start_date"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <HubInputField
                            inputId="start_date"
                            isRowBased={false}
                            isRequired={false}
                            field={field}
                            inputType="date"
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <Controller
                        name="end_date"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <HubInputField
                            inputId="end_date"
                            isRowBased={false}
                            isRequired={false}
                            field={field}
                            inputType="date"
                          />
                        )}
                      />
                    </div>
                    {/*<div className="col-12 col-sm-12 col-md-4">
                      <Controller
                        name="filter"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable={true}
                            options={(filterCaseData && filterCaseData.length > 0 ?
                              filterCaseData.map((item) => ({
                                value: item.value,
                                label: item.label
                              }))
                              : []
                            ) as unknown as OptionsOrGroups<string, GroupBase<string>>}
                            id="period"
                            {...field}
                            placeholder={'Compare...'}
                          />
                        )}
                      />
                    </div>*/}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row g-2">
                    {userData && userData.ref_type !== "rep" ? (
                      <div className="col-12 col-sm-12 col-md-4">
                        <Controller
                          name="user_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isDisabled={userData ? userData.ref_type === "rep" : false}
                              isClearable={true}
                              options={(
                                userListing && userListing.length > 0 ?
                                  userListing
                                      .sort((a, b) => a.fullname.localeCompare(b.fullname))
                                      .map((user) => ({ value: user.id.toString(), label: user.fullname }))
                                  : []
                              ) as unknown as OptionsOrGroups<string, GroupBase<string>>}
                              id="user_id"
                              placeholder="Account Director..."
                              {...field}
                              value={userData && userData.ref_type !== "rep" && populateCurrentUserInDropdown(field.value)}
                            />
                          )}
                        />
                      </div>
                    ) : null}
                    <div className={`col-12 col-sm-12 col-md-${userData && userData.ref_type === "rep" ? 6 : 4}`}>
                      <Controller
                        name="soc_value"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isClearable={true}
                            options={(dataSitofCare && dataSitofCare.length > 0 ?
                              dataSitofCare.map((item) => ({
                                value: item.value,
                                label: item.name
                              }))
                              : []
                            ) as unknown as OptionsOrGroups<string, GroupBase<string>>}
                            id="soc_value"
                            {...field}
                            placeholder={'All SOC ...'}
                          />
                        )}
                      />
                    </div>
                    <div className={`col-12 col-sm-12 col-md-${userData && userData.ref_type === "rep" ? 6 : 4}`}>
                      <Controller
                        name="drug_id"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <Select
                            isClearable={true}
                            options={
                              (productData && productData.length > 0
                                ? productData.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))
                                : []) as unknown as OptionsOrGroups<
                                string,
                                GroupBase<string>
                              >
                            }
                            id="drug_id"
                            {...field}
                            placeholder={"All Product ..."}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-1 mb-3">
              <button type="submit" className="btn btn-primary w-100">Refresh</button>
            </div>
          </div>
          <StatsReports getValues={getValues} />
        </div>

      </div>

    </form >
  );
};

export default CaseFilter;
