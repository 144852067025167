import { FC } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import HubInputField from "../../inputType";

import { PATIENT_INSURANCE_TYPE } from "src/constants/cases";
import { SingleInsuranceProps } from "./types";



const SinglePatientInsurance: FC<SingleInsuranceProps> = ({ index, control, watch, removeInsurance }): JSX.Element => {
  const relationType = watch(`insurances_attributes.${index}.relation_to_patient`);

  return (
    <div className="card p-4 mb-3 bg-light-subtle border-light-subtle">
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <Controller
            name={`insurances_attributes.${index}.insurance_name`}
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                isRowBased={true}
                isRowInsideAnother={true}
                isRequired={true}
                field={field}
                inputLabel="Insurance Name"
                inputId={`insurances_attributes.${index}.insurance_name`}
                error={error}
              />
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12">
          <Controller
            name={`insurances_attributes.${index}.relation_to_patient`}
            control={control}
            defaultValue="self"
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                field={field}
                error={error}
                inputLabel="Relation to Patient"
                inputId={`insurances_attributes.${index}.relation_to_patient`}
                isRequired={false}
                isRowBased={true}
                isDropdown={true}
                isSearchable={false}
                dropdownData={PATIENT_INSURANCE_TYPE as unknown as OptionsOrGroups<string, GroupBase<string>>}
              />
            )}
          />
        </div>
      </div>

      {relationType !== "self" ? (
        <div className="row">
          <label className="form-label col-lg-2 col-md-2 col-12">Relative Name</label>
          <div className="col-lg-10 col-md-10">
            <div className="row">
              <div className="col-lg-4">
                <Controller
                  name={`insurances_attributes.${index}.relation_firstname`}
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <HubInputField
                      inputId={`insurances_attributes.${index}.relation_firstname`}
                      inputPlaceholder="First Name"
                      isRequired={true}
                      isRowBased={true}
                      field={field}
                      error={error}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4">
                <Controller
                  name={`insurances_attributes.${index}.relation_middlename`}
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <HubInputField
                      inputId={`insurances_attributes.${index}.relation_middlename`}
                      inputPlaceholder="Middle Name"
                      isRequired={true}
                      isRowBased={true}
                      field={field}
                      error={error}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4">
                <Controller
                  name={`insurances_attributes.${index}.relation_lastname`}
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => (
                    <HubInputField
                      inputId={`insurances_attributes.${index}.relation_lastname`}
                      inputPlaceholder="Last Name"
                      isRequired={true}
                      isRowBased={true}
                      field={field}
                      error={error}
                    />
                  )}
                />
              </div>
            </div>

          </div>
        </div>
      ) : null}

      {/* Medical Insurance */}
      <div className="py-2">
        <h5 className="text-primary">Medical Insurance</h5>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <Controller
              name={`insurances_attributes.${index}.policy`}
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRowInsideAnother={true}
                  isRequired={true}
                  field={field}
                  inputLabel="Member ID"
                  inputId={`insurances_attributes.${index}.policy`}
                  inputPlaceholder=""
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <Controller
              name={`insurances_attributes.${index}.group_number`}
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRequired={false}
                  field={field}
                  inputLabel="Group"
                  inputId={`insurances_attributes.${index}.group_number`}
                  inputPlaceholder=""
                  error={error}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name={`insurances_attributes.${index}.insurance_number`} control={control} defaultValue=""
          render={({field, fieldState: {error}}) => (
            <HubInputField
              isRowBased={true}
              isRequired={false}
              field={field}
              inputLabel="Phone"
              inputId={`insurances_attributes.${index}.insurance_number`}
              inputPlaceholder=""
              error={error}
            />
          )}
        />
      </div>

      {/* Pharmacy Insurance */}
      <div className="py-2">
        <h5 className="text-primary">Pharmacy Insurance</h5>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <Controller
              name={`insurances_attributes.${index}.member_id`}
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRowInsideAnother={true}
                  isRequired={false}
                  inputLabel="Rx Member ID"
                  inputId={`insurances_attributes.${index}.member_id`}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <Controller
              name={`insurances_attributes.${index}.bin`}
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRequired={false}
                  field={field}
                  inputId={`insurances_attributes.${index}.bin`}
                  inputLabel="BIN"
                  inputPlaceholder=""
                  error={error}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <Controller 
              name={`insurances_attributes.${index}.pcn`}
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRowInsideAnother={true}
                  isRequired={false}
                  inputId={`insurances_attributes.${index}.pcn`}
                  inputLabel="PCN"
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <Controller
              name={`insurances_attributes.${index}.rx_group`}
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  isRowBased={true}
                  isRequired={false}
                  field={field}
                  inputId={`insurances_attributes.${index}.rx_group`}
                  inputLabel="Rx Group"
                  inputPlaceholder=""
                  error={error}
                />
              )}
            />
          </div>
        </div>
      </div>

      <Controller
        name={`insurances_attributes.${index}.global_insurance_id`}
        control={control}
        render={() => (<></>)}
      />
      <Controller
        name={`insurances_attributes.${index}.is_primary_insurance`}
        control={control}
        render={() => (<></>)}
      />
      <Controller
        name={`insurances_attributes.${index}.insurance_priority`}
        control={control}
        defaultValue={index}
        render={() => (<></>)}
      />

      {index !== 0 ? (
        <div className="text-end">
          <button type="button" className="btn btn-danger btn-sm" onClick={() => removeInsurance(index)}>
            Delete this Insurance
          </button>
        </div>
      ) : null}

    </div>
  );
};

export default SinglePatientInsurance;
